import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import axios from 'axios';

import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

import Button from "../../components/button/Button";
import SupportModal from "../../components/models/SupportModel";
import PageLoader from "../../components/loaders/PageLoader";

export default function HelpPage() {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const [showSupportForm, setShowSupportForm] = useState(false);
    const [supportFormType, setSupportFormType] = useState("academic");

    const getPageData = () => {
        let config = {
            method: 'get',
            url: `${window.backendUrl}/api/v1/contact/support`,
            headers: {
                'Content-Type': 'application/json'
            }
        };

        axios.request(config)
            .then((response) => {
                setData(response?.data?.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                Swal.fire({
                    title: 'Error',
                    text: 'An error occurred. Please try again!',
                    icon: 'error',
                    confirmButtonText: 'Close'
                });
            });
    }

    const handleSupportType = async (type) => {
        setSupportFormType(type);
        setShowSupportForm(true);
    }

    useEffect(() => {
        getPageData();
    }, []);

    return (
        <PageWrapper>
            <SupportModal
                visible={showSupportForm}
                onClose={() => setShowSupportForm(false)}
                supportFormType={supportFormType}
            />
            <h2 className="page-title">Help and Support</h2>
            <div className="row">
                <div className="col-rs-3"></div>
                <div className="col-rs-6" style={{ pAlign: 'center' }}>
                    {loading ? 
                        <PageLoader open={loading} />
                        : <>
                        <p className="heading">Academic Support</p>
                        {
                            data.filter((i) => i.category === 'noun').map((item) => (
                                <div style={{ marginTop: 10 }} key={item._id}>
                                    {item.type === 'web' &&
                                        <Link onClick={() => handleSupportType('academic')} style={{ textDecoration: 'none' }}>
                                            <p className="text" style={{ color: '#1D8A50' }}><WhatsAppIcon style={{ fontSize: 14 }} /> {item.displayName}</p>
                                        </Link>
                                    }
                                    {item.type === 'call' &&
                                        <Link to={`tel: +234${item.name.substr(-10)}`} target="_blank" style={{ textDecoration: 'none' }}>
                                            <p className="text" style={{ color: '#1D8A50' }}><PhoneIcon style={{ fontSize: 14 }} /> {item.name}</p>
                                        </Link>
                                    }
                                    {item.type === 'whatsapp' &&
                                        <Link to={`https://wa.me/234${item.name.substr(-10)}`} target="_blank" style={{ textDecoration: 'none' }}>
                                            <p className="text" style={{ color: '#1D8A50' }}><WhatsAppIcon style={{ fontSize: 14 }} /> {item.name}</p>
                                        </Link>
                                    }
                                    {item.type === 'email' &&
                                        <Link to={`mailto:${item.name}`} target="_blank" style={{ textDecoration: 'none' }}>
                                            <p className="text" style={{ color: '#1D8A50' }}><EmailIcon style={{ fontSize: 14 }} /> {item.name}</p>
                                        </Link>
                                    }
                                </div>
                            ))
                        }

                        <p className="heading">Mobile App Support</p>
                        {
                            data.filter((i) => i.category === 'intradot').map((item) => (
                                <div style={{ marginTop: 10 }} key={item._id}>
                                    {item.type === 'web' &&
                                        <Link to={item.name} target="_blank" style={{ textDecoration: 'none' }}>
                                            <p className="text" style={{ color: '#1D8A50' }}><WhatsAppIcon style={{ fontSize: 14 }} /> {item.displayName}</p>
                                        </Link>
                                    }
                                    {item.type === 'call' &&
                                        <Link to={`tel: +234${item.name.substr(-10)}`} target="_blank" style={{ textDecoration: 'none' }}>
                                            <p className="text" style={{ color: '#1D8A50' }}><PhoneIcon style={{ fontSize: 14 }} /> {item.name}</p>
                                        </Link>
                                    }
                                    {item.type === 'whatsapp' &&
                                        <Link to={`https://wa.me/234${item.name.substr(-10)}`} target="_blank" style={{ textDecoration: 'none' }}>
                                            <p className="text" style={{ color: '#1D8A50' }}><WhatsAppIcon style={{ fontSize: 14 }} /> {item.name}</p>
                                        </Link>
                                    }
                                    {item.type === 'email' &&
                                        <Link to={`mailto:${item.name}`} target="_blank" style={{ textDecoration: 'none' }}>
                                            <p className="text" style={{ color: '#1D8A50' }}><EmailIcon style={{ fontSize: 14 }} /> {item.name}</p>
                                        </Link>
                                    }
                                </div>
                            ))
                        }

                        <p className="heading" style={{ marginBottom: 0, paddingBottom: 0 }}>Send Us a Direct Message</p>
                        <p className="itemText">(A response message will be send to your email)</p>
                        <p className="text">Support Type</p>
                        <div className="row">
                            <div className="left">
                                <Button text="Academic"
                                    onClick={() => handleSupportType('academic')}
                                />
                            </div>
                            <div className="right">
                                <Button text="Mobile App"
                                    onClick={() => handleSupportType('mobile-app')}
                                />
                            </div>
                        </div>
                    </>}
                </div>
                <div className="col-rs-3"></div>
            </div>
        </PageWrapper>
    );
}

const PageWrapper = styled('div')(() => ({
    backgroundColor: '#fff',
    width: '100%',
    padding: '30px',
    boxSizing: 'border-box',
    minHeight: '75vh',

    '& .heading': {
        fontSize: 18,
        textAlign: "left",
        fontWeight: "bold",
        paddingTop: 0,
        color: '#333'
    },
    '& .title': {
        fontSize: 14,
        textAlign: "left",
        fontWeight: "bold",
        padding: 0,
        color: '#333',
        marginTop: 0,
        paddingTop: 0
    },
    '& .text': {
        fontSize: 14,
        textAlign: "left",
        padding: 0,
        marginTop: 0,
        paddingTop: 0
    },
    '& .row': {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    '& .left': {
        width: "49%",
        borderWidth: 0,
        textAlign: 'left',
    },
    '& .right': {
        width: "49%",
        borderWidth: 0,
        textAlign: 'right',
    },
    '& .itemText': {
        fontSize: 12,
        paddingTop: 0
    },

}));
