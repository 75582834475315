import React, { useState } from "react";
import { styled } from "@mui/system";
import axios from 'axios';
import Swal from "sweetalert2";

import Button from "../button/Button";

export default function MobileAppSupportForm() {
    const [message, setMessage] = useState('');
    const [buttonLoading, setButtonLoading] = useState(false);

    const handleSubmit = () => {
        setButtonLoading(true);

        if (!message) {
            Swal.fire({
                title: 'Error!',
                text: 'Provide a message for us',
                icon: 'error',
                confirmButtonText: 'Close'
            });
            setButtonLoading(false);
        } else {
            let data = JSON.stringify({
                supportType: "Mobile App",
                "message": message
            });

            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: `${window.backendUrl}/api/v1/contact/message`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("userToken")}`
                },
                data: data
            };

            axios.request(config)
                .then((response) => {
                    //Alert.alert(response?.data?.title, response?.data?.message);
                    Swal.fire({
                        title: response?.data?.title,
                        text: response?.data?.message,
                        icon: 'success',
                        confirmButtonText: 'Close'
                    });
                    setButtonLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    Swal.fire({
                        title: 'Error',
                        text: 'Network error try again!',
                        icon: 'error',
                        confirmButtonText: 'Close'
                    });
                    setButtonLoading(false);
                });


        }
    }

    return (
        <PageWrapper>
            <div>
                <p className="title" style={{ fontSize: 20, marginBottom: 10, }} >Mobile App Support</p>
                <p className="itemText" style={{ marginTop: 0, paddingTop: 0 }}>(Only issue related to the mobile app)</p>
            </div>
            <p className="title">Message</p>
            <div>
                <textarea
                    className="input"
                    placeholder={"Message"}
                    placeholderpColor="gray"
                    style={{ height: 100, borderRadius: 8, border: '1px solid #d3d3d3', justifyContent: 'flex-start' }}
                    autoCapitalize="none"
                    keyboardType="default"
                    returnKeyType="next"
                    multiline={true}
                    onChange={(e) => setMessage(e.target.value)} ></textarea>
            </div>
            <Button text="Submit" onClick={() => handleSubmit()} isLoading={buttonLoading} />
        </PageWrapper>
    );
}

const PageWrapper = styled('div')(() => ({
    backgroundColor: '#fff',
    width: '100%',
    padding: '0px',
    boxSizing: 'border-box',
    minHeight: '75vh',

    '& .heading': {
        fontSize: 18,
        textAlign: "left",
        fontWeight: "bold",
        paddingTop: 0,
        color: '#333'
    },
    '& .title': {
        fontSize: 14,
        textAlign: "left",
        fontWeight: "bold",
        padding: 0,
        color: '#333',
        marginTop: 0,
        paddingTop: 0
    },
    '& .text': {
        fontSize: 14,
        textAlign: "left",
        padding: 0,
        marginTop: 0,
        paddingTop: 0
    },
    '& .row': {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    '& .left': {
        width: "49%",
        borderWidth: 0,
        textAlign: 'left',
    },
    '& .right': {
        width: "49%",
        borderWidth: 0,
        textAlign: 'right',
    },
    '& .itemText': {
        fontSize: 12,
        paddingTop: 0
    },

}));