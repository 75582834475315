import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import axios from 'axios';
import Swal from "sweetalert2";

import Button from "../button/Button";
import { useSelector } from "react-redux";

export default function AcademicSupportForm() {
    const profile = useSelector((store) => store.user.profile);
    const [category, setCategory] = useState(null);
    const [itemsCategory, setItemsCategory] = useState([
        { value: 0, label: "ACADEMIC REGISTRY" },
        { value: 1, label: "Business Creation and Growth (GST 302)" },
        { value: 2, label: "ELEARN PORTAL" },
        { value: 3, label: "EMAIL AND COURSEWARE" },
        { value: 4, label: "eTICKETING PASSWORD RESET" },
        { value: 5, label: "EXAMINATION / RESULT ISSUES" },
        { value: 6, label: "FACULTY / DEPARTMENTAL ISSUES" },
        { value: 7, label: "INDUSTRIAL TRAINING (SIWES)" },
        { value: 8, label: "POSTGRADUATE (SPGS)" },
        { value: 9, label: "STUDENTS PORTAL ISSUES" },
        { value: 10, label: "STUDENTS' ACCOUNT" }
    ]);

    const [subCategory, setSubCategory] = useState(null);
    const [itemsSubCategory, setItemsSubCategory] = useState([
        { value: "e72818c7-6256-47bb-a572-ebb761ee083f", label: "Academic Registry Issue", categoryId: 0 },
        { value: "76411667-4422-4652-b960-81a6ce1ea798", label: "Enquiries On Certificate Collection", categoryId: 0 },
        { value: "b3ec6540-8ce4-4f59-a44e-eadf49d7c9f5", label: "Change Of Study Centre", categoryId: 0 },
        { value: "9b596de5-c76e-4b00-8588-9cf5e3839918", label: "Correction Of Name", categoryId: 0 },
        { value: "6e8502fa-f540-4f05-81c5-b56482cd41e5", label: "Correction Of Name", categoryId: 0 },
        { value: "fe6daea6-cdcc-4342-8dce-38ec474c9207", label: "Wrong Passport Upload", categoryId: 0 },
        { value: "6ac485a9-642d-4d05-ab3d-7d41f28d0d7c", label: "NYSC Issues", categoryId: 0 },
        { value: "5f0da552-87e1-4f61-ab1b-2bc96e1594b0", label: "Admission Enquiries", categoryId: 0 },
        { value: "9ab403a4-f3e8-465f-904e-d3491e5d130b", label: "Change of Entry Level", categoryId: 0 },
        { value: "9eb27786-e2a4-4b7e-a97f-d8a4c70d8b34", label: "Project Moderation issues (UG)", categoryId: 0 },
        { value: "db890773-6d5b-4b01-9d70-d6d4d50231fc", label: "Change of Exam Venue", categoryId: 0 },
        { value: "cc7ba663-ec18-4765-98ab-14df15dbf9ec", label: "Student General Enquiries", categoryId: 0 },
        { value: "390dcc09-51eb-4c15-89da-d2d038dc48c1", label: "Old Student Re-enrolment", categoryId: 0 },
        { value: "36cf1c08-5aed-4e7f-b562-2e52825402cf", label: "Undergraduate Transcript Enquiries", categoryId: 0 },
        { value: "1fa6bf08-5ae6-4781-a492-bfc6b8538556", label: "Error Of Name On Certificate", categoryId: 0 },
        { value: "a0845091-239f-481c-9cff-3a19633ebafe", label: "Undergraduate Transcript", categoryId: 0 },
        { value: "36138bc6-59db-4b5d-8d04-c8f64a1ca3fb", label: "Passport Issues", categoryId: 0 },
        { value: "f75e78ee-ef94-4542-a4b3-fc6f33ab3f10", label: "Correction of Date Of Birth", categoryId: 0 },
        { value: "0d607941-6eff-43f9-8b62-88db5cc92c5b", label: "Correction of Passport Upload", categoryId: 0 },
        { value: "56d482ba-74a4-45f3-a0d7-ea42694b009d", label: "ISSUES RELATING TO GST302", categoryId: 1 },
        { value: "dcfa0dff-071a-4238-a249-a472e4018a53", label: "Course(S) Not On Elearn Portal", categoryId: 2, },
        { value: "b7b0a079-b640-4b0b-ae53-e22458ee7fa6", label: "eLearn Login Issues", categoryId: 2, },
        { value: "82e6a9d2-6d3c-4de8-8cf5-97d08b750a3e", label: "Undergraduate Upload Of Projects/Seminar/Practicum/Teaching Practice", categoryId: 2, },
        { value: "600f8878-f3b6-4814-a3b2-68e730a8abd1", label: "Student NOUN Email Issue", categoryId: 3 },
        { value: "95f99af9-009b-44fb-9b16-e2c5384bae91", label: "No Email Address", categoryId: 3 },
        { value: "8772973d-9f58-4542-8ed1-d2dddaf3158c", label: "Ecourseware Issues", categoryId: 3 },
        { value: "2fca567c-6491-4c60-829e-6df8d72101cf", label: "Password Reset", categoryId: 4 },
        { value: "f6ed20e6-f090-4562-8635-a671cacf0948", label: "Unable to View Result", categoryId: 5 },
        { value: "ffde8d08-ff51-4203-b436-34c3a351f297", label: "Missing Result", categoryId: 5 },
        { value: "fd0dac3b-ac77-42d6-82be-1bbb8dcc21e7", label: "Remarking of Examination Script", categoryId: 5 },
        { value: "5759fd3c-293c-4ec7-b877-87f4162675ea", label: "Other Result Enquiries", categoryId: 5 },
        { value: "d2277619-a807-4ad7-94a9-7b2c4b524d17", label: "Change of Exam Venue", categoryId: 5 },
        { value: "cd80583e-bfb7-4bf2-a6c1-74bada058e78", label: "Exam Related Issues (eExam & POP)", categoryId: 5 },
        { value: "63650c6b-616b-42e0-a712-eee7841db497", label: "Change Of Programme", categoryId: 6 },
        { value: "411e0b10-caaa-4734-9a07-40640a143038", label: "Change Of Entry Level", categoryId: 6 },
        { value: "4f48b24b-7594-4c0c-91fc-a2403a012615", label: "Graduation Related Issues", categoryId: 6 },
        { value: "638801bd-f778-42ea-9616-8ea1a16e7aa4", label: "Missing Results", categoryId: 6 },
        { value: "f39b2905-664a-40fe-bc20-a16b610b8f04", label: "Incomplete Result", categoryId: 6 },
        { value: "6c6f0966-46ac-4116-bd98-81ddce43146e", label: "Discrepancies In CGPA", categoryId: 6 },
        { value: "324fe7a7-eba8-499b-8e72-e200887f3a0c", label: "Remarking Of Examination Scripts", categoryId: 6 },
        { value: "3b6ac885-498c-427a-a88e-61b3772de0c6", label: "Change Of Programme Faculty of Education", categoryId: 6 },
        { value: "8b6f6409-877b-45aa-ad46-bcb1c8febef0", label: "Change Of Entry Level Faculty of Education", categoryId: 6 },
        { value: "6149d38c-c809-4363-95cd-76ee234f03b4", label: "Graduation Related Issues Faculty of Education", categoryId: 6 },
        { value: "3dc35a85-0392-4c12-8e2f-e5d5f12dff33", label: "Graduation Related Issues (Faculty of Law)", categoryId: 6 },
        { value: "2355c83d-a271-4318-8789-30a9ff91b8c5", label: "Change of Programme (Faculty of Law)", categoryId: 6 },
        { value: "bf0daffc-0946-4efa-bf92-347ab8e9e94c", label: "Change of Entry Level (Faculty of Law)", categoryId: 6 },
        { value: "3426ffae-0afa-4683-be5d-7bc70a64b18f", label: "Change of Programme (Faculty of Management Sciences)", categoryId: 6 },
        { value: "cc43e722-32bd-4609-bd90-8a5944b08935", label: "Change of Entry Level (Faculty of Management Sciences)", categoryId: 6 },
        { value: "52f698de-124c-4377-b97b-9bd93c1d6c3f", label: "Graduation Related Issues (Faculty of Management Sciences)", categoryId: 6 },
        { value: "7bc4d9cd-77de-4306-b066-d0156bb06522", label: "Change of Programme (Faculty of Science)", categoryId: 6 },
        { value: "79afa755-41e6-4b1e-874c-f062723a95d2", label: "Change of Entry Level (Faculty of Science)", categoryId: 6 },
        { value: "a217029e-4d98-45d8-a362-1e89e45e4034", label: "Graduation Related Issues (Faculty of Science)", categoryId: 6 },
        { value: "c7d52113-162d-4275-9ce8-a87d7b921c95", label: "Change Of Entry Level (Faculty of Agric. Science)", categoryId: 6 },
        { value: "018ba71f-54fe-4fae-8609-b71b925e736c", label: "Change Of Programme (Faculty of Agric. Science)", categoryId: 6 },
        { value: "e6c364b0-4f1b-4396-9d53-6b3a2243d34a", label: "Graduation Related Issues (Faculty of Agric. Science)", categoryId: 6 },
        { value: "a8275e5c-795a-40a2-bede-2c7530b76c15", label: "Change Of Entry Level (Faculty of Social Science And Faculty Of Arts)", categoryId: 6 },
        { value: "b6ed43fa-139a-43c9-9076-a1881be5738a", label: "Change Of Programme (Faculty of Social Science And Faculty Of Arts)", categoryId: 6 },
        { value: "0d998868-c714-462c-a726-a8215bef9886", label: "Change Of Programme (Faculty of Health Science)", categoryId: 6 },
        { value: "091d6c6c-ccee-46c5-9b41-53298f3c185f", label: "Change Of Entry Level (Faculty of Health Science)", categoryId: 6 },
        { value: "dcf877da-ac0c-40ec-8f76-ae6fca675b27", label: "Graduation Related Issues (Faculty of Health Science)", categoryId: 6 },
        { value: "20241f23-9e07-4b9b-84bc-9222b40b8f90", label: "Graduation Related Issues (Faculty of Social Science and Faculty of Arts)", categoryId: 6 },
        { label: "Login Issues On Siwes Platform", value: "0d8d08a0-ac40-4a6f-b477-535fd58cb524", categoryId: 7 },
        { label: "Payment For The Logbook / Enquiries", value: "28185f36-aa70-41c0-99f3-841bcda88065", categoryId: 7 },
        { label: "Registration For Siwes Courses", value: "3671be91-55bf-4a85-b5ff-8140882fd50c", categoryId: 7 },
        { label: "Missing Siwes Result", value: "eb98fe43-618d-49ad-82e6-c1618f4a81d2", categoryId: 7 },
        { label: "Change Of Email On Siwes Portal", value: "1f272e5a-736f-454a-b350-2b8e292fadb3", categoryId: 7 },
        { label: "How To Login To The SIWES Platform", value: "e721f6f2-d141-489a-92fa-e80e41d6f325", categoryId: 7 },
        { label: "Industrial Training Fund Stipends", value: "14fd37ee-62bb-4cb0-a42c-5be7830ccc7b", categoryId: 7 },
        { label: "Change Of Password", value: "900d573d-ca9a-481c-be16-09956c96202c", categoryId: 7 },
        { label: "Postgraduate Transcript Enquiries", value: "63a34624-d3ab-4423-8c81-dc01d6ba2b70", categoryId: 8 },
        { label: "Postgraduate Admissions (Pgd And Masters)", value: "4d724fd6-b29f-444e-a880-90137fe6db5f", categoryId: 8 },
        { label: "PhD ADMISSIONS", value: "f2c66f00-a1f7-4c79-bc4a-f36c6bed703e", categoryId: 8 },
        { label: "Postgraduate Research Projects, Teaching Practice And Practicum Result", value: "ba894bae-a5ad-4b44-ae6a-eb58aa1b4800", categoryId: 8 },
        { label: "Graduation Status", value: "12bf41fa-7444-4add-a23b-862c6f012f81", categoryId: 8 },
        { label: "Login Issue", value: "41131eca-f082-4bf9-8561-34a22565d26a", categoryId: 9 },
        { label: "eWallet Balance Issue", value: "56b2e701-bf86-4a0c-932c-9b1a822d48a7", categoryId: 9 },
        { label: "Dropping Of Courses", value: "9424b462-3965-489e-9cc3-6b8c6bb833eb", categoryId: 9 },
        { label: "Wallet Funding", value: "e6b64f63-7e0c-45d5-9424-83db9adae9d3", categoryId: 9 },
        { label: "Course / Exams Registration Issue", value: "ff3675cb-4412-4fb5-a2cd-88f96ff7f2ec", categoryId: 9 },
        { label: "Email update", value: "29cc0cd7-5b88-43ab-b8a2-5e4b1b228827", categoryId: 9 },
        { label: "E-course ware", value: "28bc051b-e0db-41cf-a924-f944bb754a95", categoryId: 9 },
        { label: "Irregular Debit during Course Registration", value: "46fc74e9-6687-46c5-83c2-5fcf6974d8ad", categoryId: 9 },
        { label: "Semester Fees Omission", value: "011daac2-5976-44d4-823f-90e8328f753f", categoryId: 10 },
        { label: "Transfer Of Previous Portal Balance", value: "01d1ba75-d9a6-4d84-b74f-280dda4bd504", categoryId: 10 },
        { label: "Graduate Final Clearance", value: "6e5c5e93-1a7b-49ff-971c-f2bce409b615", categoryId: 10 },
        { label: "Printing of Student Wallet", value: "1c8eb024-0a20-4b88-8295-c2e6dc1b2473", categoryId: 10 },
        { label: "IRREGULAR DEBITS IN THE WALLET", value: "84b706ce-88d3-4293-a105-fe21a3be3ee6", categoryId: 10 },
        { label: "E-Wallet Balance Issue", value: "ff72279c-5f34-4d03-b410-576e03b30262", categoryId: 10 },
        { label: "Credit Of RRR Payment", value: "2bc22b2a-ed38-489e-a744-7919fc53cf8d", categoryId: 10 },
    ]);

    const [matricNumber, setMatricNumber] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [message, setMessage] = useState("");
    const [file, setFile] = useState(null);
    const [buttonLoading, setButtonLoading] = useState(false);

    const handleSubmit = () => {
        if (!phoneNumber || !matricNumber || !subCategory) {
            Swal.fire({
                title: 'Error',
                text: 'Matric number, phone number and sub category are required!',
                icon: 'error',
                confirmButtonText: 'Close'
            });
        } else {
            setButtonLoading(true);
            const formData = new FormData();
            formData.append('phone', phoneNumber);
            formData.append('matric', matricNumber);
            formData.append('subCategoryID', subCategory);
            formData.append('message', `<p>${message}</p>`);
            formData.append('source', 'web');
            formData.append('priority', 'normal');
            formData.append('originURL', 'https://support.nou.edu.ng');
            formData.append('attachments', []); // Ensure this is correctly formatted as expected by the API

            axios.post('https://noun-api.esupport.africa/ticket/create', formData, {
                headers: {
                    'apikey': 'pJRJKQcmgU6F84F1oJp415jANIj7zCt7',
                    'Authorization': 'Bearer pJRJKQcmgU6F84F1oJp415jANIj7zCt7',
                }
            }).then(response => {
                // console.log('Response:', response.data);
                setButtonLoading(false);
                if (response?.data?.status === 'failed') {
                    Swal.fire({
                        title: response?.data?.title,
                        text: response?.data?.message,
                        icon: 'error',
                        confirmButtonText: 'Close'
                    });
                } else {
                    Swal.fire({
                        title: 'Success!',
                        text: 'Ticket created successfully!',
                        icon: 'success',
                        confirmButtonText: 'Close'
                    });
                }
            }).catch(error => {
                // console.error('Error:', error.response ? error.response.data : error.message);
                setButtonLoading(false);
                Swal.fire({
                    title: 'Error',
                    text: 'An error occurred. Please try again!',
                    icon: 'error',
                    confirmButtonText: 'Close'
                });
            });
        }
    };

    useEffect(() => {
        setMatricNumber(profile?.matric_number);
        const last10Digits = profile?.phone?.slice(-10);
        setPhoneNumber(`0${last10Digits}`);
    }, [profile]);

    return (
        <PageWrapper>
            <div>
                <p className="title" style={{ fontSize: 20, marginBottom: 10, }} >Academic Support</p>
                <p className="itemText" style={{ marginTop: 0, paddingTop: 0 }}>(Issue related to the academic)</p>
            </div>
            <div>
                <p className="text" style={{ textAlign: 'left', marginBottom: 10 }}>MATRIC NUMBER</p>
                <input style={{ borderRadius: 8 }} className="input" type="text" placeholder="Enter Matric Number" value={matricNumber} onChange={e => setMatricNumber(e.target.value)} />
                <p className="text" style={{ textAlign: 'left', marginBottom: 10 }}>PHONE NUMBER</p>
                <input style={{ borderRadius: 8 }} className="input" type="text" placeholder="Enter Phone Number" value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)} />
                <p className="text" style={{ textAlign: 'left', marginBottom: 10 }}>CATEGORY</p>
                <select className="input" value={category} onChange={e => setCategory(e.target.value)} style={{ padding: 10, borderRadius: 8 }}>
                    <option value="">Select Category</option>
                    {itemsCategory.map((item, index) => (
                        <option key={index} value={item.value}>{item.label}</option>
                    ))}
                </select>
                <p className="text" style={{ textAlign: 'left', marginBottom: 10 }}>SUBCATEGORY</p>
                <select className="input" value={subCategory} onChange={e => setSubCategory(e.target.value)} style={{ padding: 10, borderRadius: 8 }}>
                    <option value="">Select Subcategory</option>
                    {itemsSubCategory.filter(i => parseInt(i.categoryId) === parseInt(category)).map((item, index) => (
                        <option key={index} value={item.value}>{item.label}</option>
                    ))}
                </select>
                <p className="text" style={{ textAlign: 'left', marginBottom: 10 }}>ATTACHMENT(S)</p>
                <input style={{ borderRadius: 8 }} className="input" type="file" placeholder="Click to upload files here!" value={file} onChange={e => setFile(e.target.value)} />
                <p className="text" style={{ textAlign: 'left', marginBottom: 10 }}>MESSAGE</p>
                <textarea
                    className="input"
                    style={{ height: 100, borderRadius: 8, }}
                    value={message} onChange={(e) => setMessage(e.target.value)}
                    placeholder="Message"
                ></textarea>

            </div>
            <Button text="Submit" onClick={() => handleSubmit()} isLoading={buttonLoading} />
        </PageWrapper>
    );
}

const PageWrapper = styled('div')(() => ({
    backgroundColor: '#fff',
    width: '100%',
    padding: '0px',
    boxSizing: 'border-box',
    maxHeight: '75vh',
    overflowY: 'auto',

    '& .heading': {
        fontSize: 18,
        textAlign: "left",
        fontWeight: "bold",
        paddingTop: 0,
        color: '#333'
    },
    '& .title': {
        fontSize: 14,
        textAlign: "left",
        fontWeight: "bold",
        padding: 0,
        color: '#333',
        marginTop: 0,
        paddingTop: 0
    },
    '& .text': {
        fontSize: 14,
        textAlign: "left",
        padding: 0,
        marginTop: 0,
        paddingTop: 0,
        fontWeight: 500,
    },
    '& .row': {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    '& .left': {
        width: "49%",
        borderWidth: 0,
        textAlign: 'left',
    },
    '& .right': {
        width: "49%",
        borderWidth: 0,
        textAlign: 'right',
    },
    '& .itemText': {
        fontSize: 12,
        paddingTop: 0
    },

}));