import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux/store';

//window.backendUrl = "https://noun-backend-new.onrender.com";
//window.backendUrl = "http://localhost:6001";
window.backendUrl = "https://api-noun.oyawatch.com";
//window.backendUrlAi = "https://noun-api.onrender.com";
window.backendUrlAi = "https://noun-backend-api-uxx3.onrender.com";

/* 
  to copy build from local to production

  scp -r /Users/User/Documents/projects/noun/noun_web_new/build/* mgintradot@151.106.32.53:/tmp/build/
  scp -r /Users/User/Documents/projects/oya-project/oyawatch_web_frontend/build/* mgintradot@151.106.32.53:/tmp/build-oya/
  
  cd /tmp
  sudo cp -r build/* /var/www/app.nou.edu.ng/html
  sudo cp -r build-oya/* /var/www/app.oyawatch.com/html

  sudo systemctl restart nginx
*/

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
