import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';

import AcademicSupportForm from '../forms/AcademicSupportForm';
import MobileAppSupportForm from '../forms/MobileAppSupportForm';

export default function SupportModal({ visible, onClose, supportFormType }) {

    return (
        <Modal
            open={visible}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={styles.modalContainer}>
                <Box sx={styles.modalContent}>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            
                        </Typography>
                        <div style={{cursor: 'pointer'}} onClick={onClose}>
                            <CloseIcon style={{fontSize: '30px', color: '#1D8A50'}} />
                        </div>
                    </div>
                    <Box sx={styles.container}>
                        {supportFormType === "academic" && <AcademicSupportForm />}
                        {supportFormType === "mobile-app" && <MobileAppSupportForm />}
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

const styles = {
    modalContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '40px',
        marginBottom: '40px',
        borderRadius: '10px',
        padding: '20px',
        width: '50%',
        border: '0px solid red',
    },
    container: {
        padding: '10px',
    },
};