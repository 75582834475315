import React, { useEffect, useRef, useState } from 'react';
import './ChatWindow.css';
import { useDispatch, useSelector } from 'react-redux';
import { updateHistory } from '../../../redux/actions/assistanceAction';
import Logo from './noun.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const ChatWindow = () => {
    const dispatch = useDispatch();
    const profile = useSelector((store) => store.user.profile);
    const currentHistory = useSelector((store) => store.assistance.currentHistory);
    const [input, setInput] = useState('');
    const [isNewChat, setIsNewChat] = useState(true);
    const [chatId, setChatId] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [displayChatHistory, setDisplayChatHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const bottomRef = useRef(null);

    useEffect(() => {
        if(currentHistory?.chatData){
            setIsNewChat(false);
            setChatId(currentHistory?.chatData?._id);
            setChatHistory(currentHistory?.chatHistoryData);
            setDisplayChatHistory(currentHistory?.chatHistoryData);
            bottomRef.current?.scrollIntoView({ behavior: "smooth" });
        } else {
            setIsNewChat(true);
            setChatId("");
            setChatHistory([]);
            setDisplayChatHistory([]);
        }
    }, [currentHistory]);
    
    const handleSendMessage = async () => {
        if (input) {
            setLoading(true);
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", `Bearer ${localStorage.getItem("userToken")}`);

            const raw = JSON.stringify({
                "isNewChat": isNewChat,
                "chatId": chatId,
                "message": input,
                "chatHistory": chatHistory
            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            fetch(`${window.backendUrlAi}/api/v1/ai-assistance/chat`, requestOptions)
                .then((response) => response.json())
                .then((result) => {
                    console.log(result);
                    setDisplayChatHistory(result?.data?.chatHistory);
                    setLoading(false);
                    setInput('');
                    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
                    if(isNewChat){
                        const requestOptions2 = {
                            method: "GET",
                            headers: myHeaders,
                            redirect: "follow"
                            };
                    
                            fetch(`${window.backendUrl}/api/v1/ai-assistance/chats`, requestOptions2)
                            .then((response) => response.json())
                            .then((result) => {
                                console.log(result);
                                dispatch(updateHistory(result.data));
                            })
                            .catch((error) => {
                                console.error(error)
                            });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setLoading(false);
                });
        }
    }

    const RenderMessage = ({ item }) => {

        return (
            <div
                className="messageContainerDetail"
            //style={isCurrentUser ? {justifyContent: 'flex-end'} : {justifyContent: 'flex-start'}}
            >
                {item.role === "CHATBOT" && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "left",
                            gap: "10px",
                            backgroundColor: "#F5F8F9",
                            borderRadius: "20px",
                            padding: "15px",
                            boxSizing: "border-box",
                            marginBottom: "20px",
                        }}
                    >
                        <div className="avatarContainer">
                            <p className="avatarText"></p>
                        </div>
                        <div className="messageContentOthers">
                            <div className="messageHeader">
                                <div className="senderText" style={{display: "flex", flexDirection: "row", justifyContent: "left", alignContent: "center", alignItems: "center", color: "#1D8A50", gap: "5px"}}><img src={Logo} alt='Logo' width={"18px"} /> <b>AI Assistant</b></div>
                            </div>
                            <p className="messageText" style={{padding: 0, margin: 0,}}>{item?.message}</p>
                        </div>
                    </div>
                )}
                {item.role === "USER" && (
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            justifyContent: "right",
                            gap: "10px",
                            backgroundColor: "#DCEDE5",
                            borderRadius: "20px",
                            padding: "15px",
                            boxSizing: "border-box",
                            marginBottom: "20px",
                        }}
                    >
                        <div className="messageContentMe">
                            <div className="messageHeader">
                                <div className="senderText" style={{display: "flex", flexDirection: "row", justifyContent: "left", alignContent: "center", alignItems: "center", color: "#1D8A50"}}><AccountCircleIcon style={{color: "#1D8A50"}} color='#1D8A50' /> <b>{profile?.name}</b></div>
                            </div>
                            <p className="messageText" style={{padding: 0, margin: 0,}}>{item?.message}</p>
                        </div>
                        <div className="avatarContainer">
                            <p className="avatarText"></p>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="chat-window">
            <div style={{textAlign: "center"}}>
                <img src={Logo} alt='Logo' />
            </div>
            <div className='title'>{!currentHistory?.chatData?.title && "Hello! How can i assist you today?"}</div>
            <div className="messages">
            <div
              style={{
                padding: 20,
                overflow: "auto",
              }}
            >
                {displayChatHistory?.map((item, index) => (
                    <RenderMessage key={index} item={item} />
                ))}
                <div ref={bottomRef} />
            </div>
            </div>
            <div className="input-container">
                <input
                    className='input'
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' ? handleSendMessage() : null}
                    placeholder='Ask AI Assistant anything?'
                />
                <button className='button' disabled={!input || loading} onClick={() => handleSendMessage()}>{loading ? "Loading" : "Send"}</button>
            </div>
        </div>
    );
};

export default ChatWindow;
